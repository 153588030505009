
















import { defineComponent } from '@nuxtjs/composition-api';

export type LandingPage = Blok & {
  gap: number;
  sections: Blok[];
};

export default defineComponent({
  props: {
    blok: { type: Object as () => LandingPage, required: true },
  },
});
